<template>
  <div>
    <Header title="工单新增" color="#666" />
    <van-form
      input-align="right"
      error-message-align="right"
      class="form"
      @submit="onSubmit"
    >
      <FieldLabel name="事件名称" :required="true" />
      <van-field
        type="textarea"
        class="mgb10"
        v-model="form.name"
        name="name"
        autosize
        rows="2"
        maxlength="500"
        show-word-limit
        placeholder="请填写事件名称"
        clearable
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />

      <van-field
        clickable
        readonly
        v-model="form.station"
        name="station"
        label="所在站点"
        placeholder="选择所在站点"
        is-link
        @click="showStationPicker = true"
        :rules="[{ required: true }]"
      />

      <van-field
        readonly
        clickable
        v-model="form.append_time"
        name="append_time"
        label="发生时间"
        placeholder="请选择发生时间"
        is-link
        required
        @click="showAppendTimePicker = true"
        :rules="[{ required: true }]"
      />

      <van-field
        readonly
        clickable
        v-model="form.end_time"
        name="end_time"
        label="截至时间"
        placeholder="请选择截至时间"
        is-link
        required
        @click="showEndTimePicker = true"
        :rules="[{ required: true }]"
      />

      <van-field
        clickable
        readonly
        class="mgt10"
        v-model="form.source"
        name="source"
        label="事件来源"
        placeholder="选择事件来源"
        is-link
        @click="showSourcePicker = true"
        :rules="[{ required: true }]"
      />

      <van-field
        clickable
        readonly
        v-model="form.type"
        name="type"
        label="事件类别"
        placeholder="选择事件类别"
        is-link
        @click="showTypePicker = true"
        :rules="[{ required: true }]"
      />

      <FieldLabel name="联系人" :required="true" />
      <van-field
        type="text"
        v-model="form.linkman"
        name="linkman"
        placeholder="请填写联系人"
        clearable
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />
      <FieldLabel name="联系方式" :required="true" />
      <van-field
        class="mgb10"
        type="text"
        v-model="form.linknum"
        name="linknum"
        placeholder="请填写联系方式"
        clearable
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />

      <FieldLabel name="事件内容" :required="true" />
      <van-field
        type="textarea"
        class="mgb10"
        v-model="form.content"
        name="content"
        autosize
        rows="2"
        maxlength="500"
        show-word-limit
        placeholder="请填写事件内容"
        clearable
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true }]"
      />

      <FieldLabel name="图片上传" />
      <van-field
        name="files"
        label=""
        label-width="0"
        input-align="left"
        error-message-align="left"
      >
        <template #input>
          <van-uploader
            v-model="imglist"
            multiple
            max-count="6"
            :after-read="upload"
          />
        </template>
      </van-field>

      <van-button
        size="large"
        class="btn"
        color="#08a9f7"
        :loading="loading"
        loading-text="提交中..."
        native-type="submit"
        >提交</van-button
      >
    </van-form>

    <!-- 所在站点选择 -->
    <van-popup v-model="showStationPicker" position="bottom">
      <van-picker
        title="选择所在站点"
        show-toolbar
        :columns="stationList"
        @confirm="selectStation"
        @cancel="showStationPicker = false"
      />
    </van-popup>

    <!-- 事件来源选择 -->
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker
        title="选择事件类别"
        show-toolbar
        :columns="typeList"
        @confirm="selectType"
        @cancel="showTypePicker = false"
      />
    </van-popup>

    <!-- 事件来源选择 -->
    <van-popup v-model="showSourcePicker" position="bottom">
      <van-picker
        title="选择事件来源"
        show-toolbar
        :columns="sourceList"
        @confirm="selectSource"
        @cancel="showSourcePicker = false"
      />
    </van-popup>

    <!-- 发生日期选择 -->
    <van-popup v-model="showAppendTimePicker" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showAppendTimePicker = false"
        @confirm="onAppendTimeConfirm"
      />
    </van-popup>

    <!-- 截至日期选择 -->
    <van-popup v-model="showEndTimePicker" position="bottom">
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :formatter="formatter"
        @cancel="showEndTimePicker = false"
        @confirm="onEndTimeConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { addGongdan } from "@/api/gongdan";
import Header from "@/components/header";
import {
  Icon,
  Toast,
  Form,
  Cell,
  Field,
  Button,
  Popup,
  Picker,
  Uploader,
  DatetimePicker
} from "vant";
import { validatePhone } from "@/utils/validate";
import FieldLabel from "@/components/fieldLabel";
export default {
  name: "GongdanAdd",
  components: {
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [DatetimePicker.name]: DatetimePicker,
    FieldLabel,
    Header
  },
  data() {
    this.mobileRules = [
      {
        required: true,
        message: "请输入电话"
      },
      {
        validator: validatePhone,
        message: "电话格式错误"
      }
    ];
    return {
      loading: false,
      typeList: [
        "逃学",
        "早恋",
        "被暴力",
        "被霸凌",
        "拐卖",
        "离家出走",
        "被抢劫",
        "无独立房间",
        "留守儿童"
      ],
      stationList: ["武原站点", "西塘桥站点", "望海站点", "秦山站点"],
      sourceList: [
        "来源1",
        "来源2",
        "来源3",
        "来源4",
        "来源5",
        "来源6",
        "来源7",
        "来源8",
        "来源9"
      ],
      form: {
        name: "",
        station: "",
        linkman: "",
        linknum: "",
        append_time: "",
        end_time: "",
        type: "",
        source: "",
        content: "",
        files: []
      },
      imglist: [],
      date: new Date(),
      showTypePicker: false,
      showStationPicker: false,
      showSourcePicker: false,
      showAppendTimePicker: false,
      showEndTimePicker: false
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
  },
  mounted() {},
  updated() {},
  methods: {
    onSubmit() {
      const _param = this.form;
      var that = this;
      console.log("submit", _param);
      that.loading = true;
      _param.files = _param.files ? JSON.stringify(_param.files) : "";
      addGongdan(_param)
        .then(() => {
          this.loading = false;
          Toast.success({
            message: "提交成功！",
            forbidClick: true
          }),
            setTimeout(function() {
              that.$router.push({ path: "/gongdan/result" });
            }, 1500);
        })
        .catch(res => {
          this.loading = false;
          console.log(res);
        });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    onAppendTimeConfirm(e) {
      this.form.append_time = this.parseTime(e, "{y}-{m}-{d}");
      this.showAppendTimePicker = false;
    },
    onEndTimeConfirm(e) {
      this.form.end_time = this.parseTime(e, "{y}-{m}-{d}");
      this.showEndTimePicker = false;
    },
    selectType(value) {
      this.form.type = value;
      this.showTypePicker = false;
    },
    selectStation(value) {
      this.form.station = value;
      this.showStationPicker = false;
    },
    selectSource(value) {
      this.form.source = value;
      this.showSourcePicker = false;
    },
    // 文件上传完毕后会触发 after-read 回调函数，获取到对应的 file 对象
    async upload(file) {
      // 这时候我们创建一个formData对象实例
      let fileList = [];
      if (!Array.isArray(file)) {
        fileList.push(file);
      } else {
        fileList = file;
      }
      let that = this;
      fileList.forEach(async file => {
        // 通过append方法添加需要的file
        // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
        const formData = new FormData();
        formData.append("file", file.file);
        // 调用uploadImage上传的接口
        const res = await uploadImage(formData);
        console.log(res);
        //[{"fileName":"20.0诉讼百科_03.png","filePath":"file\\2022\\07\\bdf10420042a11ed9f6d1e4946b92c8f.png","id":"997573742743781376"}]
        if (res.success === true) {
          that.form.files.push({
            fileName: res.data.fileName,
            filePath: res.data.filePath,
            id: res.data.id
          });
        } else {
          Toast.fail({
            message: res.message,
            forbidClick: true
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.form /deep/ {
  .btn {
    width: 175px;
    margin: 20px 100px;
  }
  .van-field__label {
    flex: none;
    width: 90px;
  }
}
</style>
